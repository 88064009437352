import { Button, ButtonGroup, Tooltip } from '@chakra-ui/react';
import { type StatBlockNodeData } from '@common/studio-types';
import {
  StatBlockLayout,
  StatBlockSectionLayout,
} from '@common/studio-types/statBlock';
import {
  UploadedImage,
  Icon,
  ImageInput,
  Textarea,
  TextInput,
} from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Field } from '../../components/Field';
import { Hint } from '../../components/Hint';
import { Label } from '../../components/Label';
import { StudioNodeFormComponent } from '../Node.types';
import { LayoutRadioSelector } from './LayoutRadioSelector';

export const StatBlockForm: StudioNodeFormComponent<StatBlockNodeData> = ({
  data,
  onChange,
}) => {
  const onUpload = (result?: UploadedImage) => {
    onChange({
      ...data,
      image: result
        ? {
            url: result.path,
            width: result.width,
            height: result.height,
          }
        : undefined,
    });
  };

  return (
    <Container>
      <Hint>
        Stat block is a simple component to display something like a character
        sheet. It has a name and a description, along with an option to add a
        group of stats.
      </Hint>
      <Field label={'Layout'}>
        <LayoutRadioSelector
          value={data.layout ?? StatBlockLayout.Compact}
          onChange={(layout) => onChange({ ...data, layout })}
        />
      </Field>
      <ImageInput
        value={data.image?.url}
        uploadPath="episodes"
        onChange={onUpload}
      />
      <Field label="Title">
        <TextInput
          placeholder="Title"
          maxCharacters={50}
          autoFocus={!data.name}
          value={data.name ?? ''}
          onChange={(e) => onChange({ ...data, name: e.target.value })}
        />
      </Field>
      <Field label="Subtitle">
        <TextInput
          placeholder="Subtitle"
          maxCharacters={50}
          value={data.subtitle ?? ''}
          onChange={(e) => onChange({ ...data, subtitle: e.target.value })}
        />
      </Field>
      <Field label="Description">
        <Textarea
          placeholder="Name"
          maxCharacters={200}
          value={data.description}
          onChange={(e) => onChange({ ...data, description: e.target.value })}
        />
      </Field>
      <Field label="Sections">
        <Hint>
          Create sections to split the information you want to display
        </Hint>
        {data.sections.map((section, index) => (
          <Field
            key={index}
            label={section.title || '(untitled)'}
            rightButton={
              <ButtonGroup>
                <Tooltip label="Delete section">
                  <Button
                    variant="icon"
                    onClick={() => {
                      const newSections = [...data.sections];
                      newSections.splice(index, 1);
                      onChange({ ...data, sections: newSections });
                    }}
                  >
                    <Icon name="trash" size={rawDimensions.size16} />
                  </Button>
                </Tooltip>
                <Tooltip label="Orientation">
                  <Button
                    variant="icon"
                    onClick={() => {
                      const newSections = [...data.sections];
                      newSections[index] = {
                        ...section,
                        layout:
                          section.layout === StatBlockSectionLayout.GridList
                            ? StatBlockSectionLayout.Description
                            : StatBlockSectionLayout.GridList,
                      };
                      onChange({ ...data, sections: newSections });
                    }}
                  >
                    <Icon
                      name={
                        section.layout === StatBlockSectionLayout.Description
                          ? 'arrow-down'
                          : 'arrow-right'
                      }
                      size={rawDimensions.size16}
                    />
                  </Button>
                </Tooltip>
                <Tooltip label="Add new field">
                  <Button
                    variant="icon"
                    onClick={() => {
                      const newField = { name: 'New field', value: '' };
                      onChange({
                        ...data,
                        sections: [
                          ...data.sections.slice(0, index),
                          {
                            ...section,
                            fields: [...section.fields, newField],
                          },
                          ...data.sections.slice(index + 1),
                        ],
                      });
                    }}
                  >
                    <Icon name="plus" size={rawDimensions.size16} />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            }
          >
            <TextInput
              placeholder="Section Title"
              maxCharacters={30}
              value={section.title}
              showRemoveButton
              onRemove={() => {
                const newSections = [...data.sections];
                newSections[index] = { ...section, title: '' };
                onChange({ ...data, sections: newSections });
              }}
              onChange={(e) => {
                const newSections = [...data.sections];
                newSections[index] = { ...section, title: e.target.value };
                onChange({ ...data, sections: newSections });
              }}
            />
            {section.fields.map((field, fieldIndex) => (
              <FieldContainer key={fieldIndex}>
                <FieldHeader>
                  <Label>Field</Label>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => {
                      const newFields = [...section.fields];
                      newFields.splice(fieldIndex, 1);
                      const newSections = [...data.sections];
                      newSections[index] = { ...section, fields: newFields };
                      onChange({ ...data, sections: newSections });
                    }}
                  >
                    Remove
                  </Button>
                </FieldHeader>
                <FieldForm layout={section.layout}>
                  <TextInput
                    fullWidth
                    placeholder="Name"
                    maxCharacters={20}
                    value={field.name}
                    onChange={(e) => {
                      const newFields = [...section.fields];
                      newFields[fieldIndex] = {
                        ...field,
                        name: e.target.value,
                      };
                      const newSections = [...data.sections];
                      newSections[index] = { ...section, fields: newFields };
                      onChange({ ...data, sections: newSections });
                    }}
                  />
                  {section.layout === StatBlockSectionLayout.Description ? (
                    <Textarea
                      placeholder="Value"
                      maxCharacters={200}
                      value={field.value}
                      onChange={(e) => {
                        const newFields = [...section.fields];
                        newFields[fieldIndex] = {
                          ...field,
                          value: e.target.value,
                        };
                        const newSections = [...data.sections];
                        newSections[index] = { ...section, fields: newFields };
                        onChange({ ...data, sections: newSections });
                      }}
                    />
                  ) : (
                    <TextInput
                      maxCharacters={20}
                      value={field.value}
                      onChange={(e) => {
                        const newFields = [...section.fields];
                        newFields[fieldIndex] = {
                          ...field,
                          value: e.target.value,
                        };
                        const newSections = [...data.sections];
                        newSections[index] = { ...section, fields: newFields };
                        onChange({ ...data, sections: newSections });
                      }}
                    />
                  )}
                </FieldForm>
              </FieldContainer>
            ))}
          </Field>
        ))}
        <Button
          variant="default"
          fontSize="14px"
          onClick={() =>
            onChange({
              ...data,
              sections: [
                ...data.sections,
                {
                  title: '',
                  fields: [],
                  layout: StatBlockSectionLayout.Description,
                },
              ],
            })
          }
        >
          Add section
        </Button>
      </Field>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${dimensions.size8};
  background: ${({ theme }) => theme.colors.background.shade};
  border-radius: ${dimensions.size8};
`;

const FieldForm = styled.div<{ layout: StatBlockSectionLayout }>`
  display: flex;
  flex-direction: ${({ layout }) =>
    layout === StatBlockSectionLayout.Description ? 'column' : 'row'};
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const FieldHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
